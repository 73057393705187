<template>
  <div style="padding: 20px">
    <p><br /></p>
    <h2>（一）检查加急权益服务</h2>
    <p>
      <u><strong>【服务特点】</strong></u>
    </p>
    <p>快速响应、高效便捷、专业可靠</p>
    <p>
      <u><strong>【什么是检查加急服务】</strong></u>
    </p>
    <p>
      检查加急服务是一项为用户提供快速医疗检查服务的选项。这项服务可协助用户在需要医疗检查时，优先进行体检项目缩短等待时间，从而更快地获得检查结果。
    </p>
    <p>
      <u><strong>【权益说明】</strong></u>
    </p>
    <p>
      (1) &nbsp; &nbsp;
      收到用户提供的预约检查单需求后，在1-7个工作日内，为服务对象落实预约检查服务。
    </p>
    <p>
      (2) &nbsp; &nbsp;
      检查当天安排具有护士执业资质的服务人员陪伴用户检查，协助办理手续，安排引导检查路径，协助用户排队等。
    </p>
    <p>
      (3) &nbsp; &nbsp;
      限车主本人120种重大疾病使用，30天激活等待期，拿到二级及以上公立医院诊断后可以发起服务申请
    </p>
    <p>(4) &nbsp; &nbsp; 车主权益券不可叠加使用，不可拆分，不兑现。</p>
    <p><br /></p>
    <p><br /></p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
